import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

let i18nLocale = "en-US";

const i18nCatalog = {};

const i18nKeys = [];

const i18nKeyConsumerInterval = setInterval(() => {}, 5000);

const Trans = ({ text }) => {
  let renderText;

  if (i18nLocale === "en-US") {
    renderText = text;
  }

  return <span>{renderText}</span>;
};

function App() {
  useEffect(() => {
    const getI18n = async () => {
      await fetch("/translations").then((res) => {
        console.log("res is", res);
      });
    };

    getI18n();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
